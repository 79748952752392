import { Component } from '@angular/core';
import { Catch } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';
import { AlternateVersionService } from '../../services/alternate-version/services/alternate-version.service';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.scss'],
})
export class LanguageSelectComponent {
  public availableLanguages = environment.availableLang.map((lang: string) => ({ key: lang, value: lang.toUpperCase() }));
  public currentLanguage = this.transloco.getActiveLang();

  constructor(private transloco: TranslocoService, private alternateVersionService: AlternateVersionService<any>) {}

  @Catch({ label: 'LanguageSelectComponent.changeLanguage()' })
  public changeLanguage() {
    this.alternateVersionService.routeToVersion(this.currentLanguage);
  }
}
