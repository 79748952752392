import { EnvironmentInterface } from './environment.interface';
import { VERSION } from './version';

export const environment: EnvironmentInterface = {
  name: 'TESTING',
  host: 'https://newtestshop.ehg-stahl.com',
  apiUrl: '/api',
  ssrPort: 13000,
  version: VERSION,
  production: true,
  debugging: false,
  defaultLocale: 'de-AT',
  defaultLanguage: 'de',
  availableLang: ['de', 'en'],
  loadTranslationFromServer: true,
  outputPath: 'dist/test/ehg-shop',
  imagePath: 'https://newtestshop.ehg-stahl.com/images',
  enventaImageSource: 'sftp://<webshop_images>/',
  documentPath: 'https://newtestshop.ehg-stahl.com/documents',
  enventaDocumentSource: 'sftp://<webshop_dokumente>/',
  auth: {
    routes: {
      home: '/',
      login: '/profile/login',
    },
  },
  api: {
    host: 'https://apitest.ehg-stahl.com',
    branchKey: 'E101',
    webshopId: 1,
  },
  cms: {
    host: 'https://newtest.ehg-stahl.com',
  },
  sentry: {
    active: true,
    dsn: 'https://c520d8ee338242b9bb2ac93790df68ce@o920539.ingest.sentry.io/6183807',
  },
  tracking: {
    gtm: '',
  },
  adAppUrl: 'https://adpwa-test.ehg-stahl.com',
};
