import { Pipe, PipeTransform } from '@angular/core';
import { Feature } from '@lobos/common';
import { EhgTranslatedFeature } from '../../interfaces/ehg-translated-feature.interface';
import { CatalogHelperService } from '../../services/catalog/catalog-helper.service';
import { EhgSteelArticle } from '../../interfaces/ehg-steel-article.interface';

@Pipe({
  name: 'featureArticleDetail',
})
export class FeatureArticleDetailPipe implements PipeTransform {
  constructor(private catalogHelper: CatalogHelperService) {}

  public transform(article: EhgSteelArticle): EhgTranslatedFeature[] {
    const mainFeatureClass = article?.oProductInfo[0]?.oFeatureClass;
    let featureFilterPredicate: (value: Feature, index: number, array: Feature[]) => boolean;
    let featureSortPredicate: (f1: Feature, f2: Feature) => number;
    if (mainFeatureClass) {
      featureFilterPredicate = (f: Feature) => {
        const mappedFeatureClass = mainFeatureClass.find((fc) => fc.lngFeatureID === f.lngFeatureID);
        return !!mappedFeatureClass && f.shtRepresentationType !== 0;
      };
      featureSortPredicate = (f1, f2) => {
        const mappedFeatureClass1 = mainFeatureClass.find((fc) => fc.lngFeatureID === f1.lngFeatureID);
        const mappedFeatureClass2 = mainFeatureClass.find((fc) => fc.lngFeatureID === f2.lngFeatureID);
        if (mappedFeatureClass1 && mappedFeatureClass2) {
          return mappedFeatureClass1.shtFCFPos - mappedFeatureClass2.shtFCFPos;
        }
        return 0;
      };
    } else {
      featureFilterPredicate = (f: Feature) => f.shtRepresentationType !== 0;
      featureSortPredicate = (f1, f2) => f1.shtRepresentationType - f2.shtRepresentationType;
    }
    // return article.oFeatures
    //   .filter(featureFilterPredicate)
    //   .map((f) => this.catalogHelper.translateFeature(f))
    //   .sort(featureSortPredicate);
    return article.oFeatures.filter(featureFilterPredicate).sort(featureSortPredicate);
  }
}
