import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { AlternateVersionInterface } from '../model/alternate-version.interface';
import { AlternateVersionStore } from '../store/alternate-version.store';
import { UrlHelperService } from '@lobos/library';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { AlternateVersionQuery } from '../query/alternate-version.query';

@Injectable({ providedIn: 'root' })
export class AlternateVersionService<T extends AlternateVersionInterface> {
  static defaultVersions: AlternateVersionInterface[] = environment.availableLang.map((lang: string) => ({
    id: lang,
    key: lang,
    title: lang.toUpperCase(),
    url: `/${lang}`,
  }));

  constructor(
    private store: AlternateVersionStore<T>,
    private query: AlternateVersionQuery<T>,
    private location: Location,
    @Inject(DOCUMENT) private readonly document: Document,
    private urlHelperService: UrlHelperService,
    private router: Router,
    private translocoService: TranslocoService,
  ) {}

  update(versions: T[]): any {
    this.updateAlternateVersionsInHtmlHead(versions);
    return this.store.update({ versions });
  }

  updateAlternateVersionsInHtmlHead(versions: T[]) {
    // remove existing alternate links
    const existingAlternateLinks = this.document.querySelectorAll('link[rel="alternate"]');
    existingAlternateLinks.forEach((alternateLink) => alternateLink.remove());

    // create new alternate links
    versions.forEach((version) => {
      if (version.key === 'de') {
        this.createAlternateVersionElement('x-default', version.url);
      }
      this.createAlternateVersionElement(version.key, version.url);
    });
  }

  private createAlternateVersionElement(hreflang: string, href: string) {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'alternate');
    link.setAttribute('hreflang', hreflang);
    this.document.head.appendChild(link);
    link.setAttribute('href', this.urlHelperService.enforceAbsoluteUrl(href));
  }

  /**
   * Replaces the language parameter in the current url
   */
  getSimple(): T[] {
    return environment.availableLang.map(
      (lang: string) =>
        ({
          id: lang,
          key: lang,
          title: lang.toUpperCase(),
          url: this.location
            .path()
            .replace(/^\/[a-z]+\//, `/${lang}/`)
            .replace(/^\/[a-z]+[^\/]$/, `/${lang}`),
        } as T),
    );
  }

  routeToVersion(language: string) {
    this.translocoService.setActiveLang(language);
    let version = this.query.getValue().versions.find((v) => v.key === language);

    if (!version) {
      version = this.getSimple().find((v) => v.key === language);
    }

    // get query params from url to avoid errors when navigating to alternate url
    const [path, queries] = version!.url.split('?');
    const params = new URLSearchParams(queries);
    const queryParams: { [key: string]: string } = {};
    params.forEach((value, key) => {
      queryParams[key] = value;
    });

    this.router.navigate([path], { queryParams }).then(() => {
      window.location.reload();
    });
  }
}
