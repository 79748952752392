<ng-container *transloco="let t; scope: 'core'">
  <div class="navigation-spacer"></div>

  <nav id="navigation" class="navigation">
    <a [href]="environment.cms.host" class="navigation-logo">
      <img src="/assets/images/logos/logo.png" alt="EHG Stahl und Metall" />
    </a>

    <button class="navigation-toggler">
      <div></div>
      <div></div>
      <div></div>
    </button>

    <div class="navigation-content">
      <div class="navigation-top">
        <div class="navigation-sales-rep" *ngIf="salesRepCustomer$ | async as salesRepCustomer">
          <span>{{
            t("core.menu.sales-rep.sales-rep-mode-active", {
              companyName: salesRepCustomer.sCompany1,
              customerId: salesRepCustomer.lngCustomerID
            })
          }}</span>
          <app-button-text [text]="t('core.menu.sales-rep.back-to-app-button')" (click)="backToApp()"></app-button-text>
        </div>
        <div class="navigation-search" [class.open]="searchIsOpen" [class.focus]="searchIsFocused">
          <app-search-box
            (searchIsOpen)="toggleSearchBox($event)"
            (searchIsFocused)="toggleSearchBoxFocus($event)"
            (searchResultClick)="closeSubMenu()"
          ></app-search-box>
        </div>

        <div class="navigation-language-chooser" [class.focus]="searchIsFocused">
          <app-language-select></app-language-select>
        </div>
      </div>

      <div class="navigation-inner">
        <div class="navigation-items">
          <!--          <div-->
          <!--            [class.navigation-item-hover]="subMenuIsOpen"-->
          <!--            (mouseenter)="toggleSubMenu($event)"-->
          <!--            (mouseleave)="toggleSubMenu($event)"-->
          <!--            class="navigation-item navigation-item&#45;&#45;menu navigation-item&#45;&#45;active"-->
          <!--          >-->
          <div
            [class.navigation-item-hover]="subMenuIsOpen"
            class="navigation-item navigation-item--menu navigation-item--active navigation-shop-link"
          >
            <a class="navigation-item-title" href="/">
              <i class="navigation-item-shop-icon icon-home-icon-1"></i>
              {{ t("core.menu.shop") }}
            </a>

            <!--            <app-navigation-item-menu-->
            <!--              (navItemClicked)="closeSubMenu()"-->
            <!--              (backButtonClicked)="closeMobileSubMenu()"-->
            <!--            ></app-navigation-item-menu>-->
          </div>

          <ng-container *ngIf="cmsNavigation">
            <div
              *ngFor="let navigationItem of cmsNavigation.items"
              class="navigation-item js-navigation-item cms-navigation-item"
              [ngClass]="navigationItem.items && navigationItem.items.length > 0 ? 'navigation-item--menu' : ''"
            >
              <a
                *ngIf="navigationItem.items?.length === 0; else navItemWithSubMenu"
                [href]="environment.cms.host + navigationItem.path"
                class="navigation-item-title js-navigation-item-title"
              >
                {{ navigationItem.title }}
              </a>
              <ng-template #navItemWithSubMenu>
                <div class="navigation-item-title js-navigation-item-title">{{ navigationItem.title }}</div>

                <div class="navigation-item-menu">
                  <button class="navigation-item-menu-back navigation-item-menu-back--line js-navigation-item-menu-back">
                    <i class="icon-arrow-left"></i><span>{{ t("core.menu.submenu-overview") }}</span>
                  </button>

                  <div class="navigation-item-menu-column">
                    <div class="navigation-item-menu-content">
                      <a
                        *ngFor="let subMenuItem of navigationItem.items"
                        [href]="environment.cms.host + subMenuItem.path"
                        class="navigation-item-menu-link"
                      >
                        <span>
                          {{ subMenuItem.title }}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </ng-template>
            </div>
            <div
              *ngFor="let item of cmsNavigation?.['highlight-navigation']"
              class="navigation-item navigation-item--highlight js-navigation-item navigation-item--highlight-first"
            >
              <a [href]="item.url" class="navigation-item-title">
                <i *ngIf="item.icon" class="navigation-item-shop-icon" [ngClass]="item.icon"></i>
                {{ item.title }}</a
              >
            </div>
          </ng-container>
        </div>

        <div
          class="navigation-buttons"
          *ngIf="{ isLoggedIn: isLoggedIn$ | async, authUser: authUser$ | async } as context"
          [class.navigation-buttons--logged-in]="context.isLoggedIn"
        >
          <app-button-text
            *ngIf="context.isLoggedIn"
            url="profile/my-profile"
            classes="navigation-button navigation-login"
            [text]="
              !!context?.authUser?.oContact?.sFirstName && !!context?.authUser?.oContact?.sLastName
                ? context?.authUser?.oContact?.sFirstName + ' ' + context?.authUser?.oContact?.sLastName
                : t('core.menu.profile')
            "
            (click)="closeSubMenu()"
            icon="icon-user"
          ></app-button-text>

          <app-button-text
            *ngIf="!context.isLoggedIn"
            (click)="navigateToLogin()"
            classes="navigation-button navigation-login"
            [text]="t('core.menu.login')"
            icon="icon-user"
          ></app-button-text>

          <app-navigation-cart
            [disabled]="!context.isLoggedIn"
            [isFavoriteLink]="true"
            class="navigation-button navigation-favorites"
            [class.navigation-cart-disabled]="!context.isLoggedIn"
            [url]="(activeFavoriteList$ | async) ? 'profile/favorites/detail/' + (activeFavoriteList$ | async)!.gListID : ''"
          ></app-navigation-cart>

          <app-button-text
            *ngIf="context.isLoggedIn"
            classes="navigation-button navigation-logout"
            [text]="t('core.menu.logout')"
            icon="icon-logout"
            (click)="logout()"
            [isIconRight]="true"
          ></app-button-text>

          <app-navigation-cart
            [disabled]="!context.isLoggedIn || !(!!('submit-order' | hasAccess | async) || !!('submit-offer' | hasAccess | async))"
            class="navigation-button navigation-cart"
            [class.navigation-cart-disabled]="
              !context.isLoggedIn || !(!!('submit-order' | hasAccess | async) || !!('submit-offer' | hasAccess | async))
            "
          ></app-navigation-cart>
        </div>
      </div>
    </div>
  </nav>
</ng-container>
