import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { StringHelper, UrlHelperService, UrlPathHelperService } from '@lobos/library';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationInterface } from '../../interfaces/navigation.interface';
import { sortBy } from 'lodash';
import { ID } from '@datorama/akita';
import { Catalog, Parameter } from '@lobos/common';

@Injectable({
  providedIn: 'root',
})
export class CatalogHelperService {
  public static emptyCharacteristicValue = '_thisValueNeverExists_';

  constructor(private translocoService: TranslocoService, private urlHelper: UrlHelperService, private location: Location) {}

  public localizedUrlPath(_: Parameter, path: string, language: string): string {
    const slashAfterCatalogNameIndex = path.indexOf('/', 2);
    const pathWithoutCatalogName = path.slice(slashAfterCatalogNameIndex + 1);
    return StringHelper.rtrim(`/${language}/${pathWithoutCatalogName}`, '/');
  }

  private getGroupsForParent(
    parentId: ID,
    parents: {
      [key: ID]: Catalog[];
    },
  ): NavigationInterface[] {
    const group: Catalog[] = parents[parentId] || [];

    return this.mapGroupToNav(sortBy(group, 'lngItemId'), parents);
  }

  private mapGroupToNav(
    catalog: Catalog[],
    parents: {
      [key: ID]: Catalog[];
    },
  ): NavigationInterface[] {
    return catalog.map((group: Catalog) => ({
      title: group.sTitle,
      url: this.getCatalogUrlPath(group),
      subNavigation: this.getGroupsForParent(group.lngGroup as number, parents),
    }));
  }

  private getCatalogUrlPath(category: Catalog) {
    return UrlPathHelperService.getUrlPath(
      typeof category?.sUrlPath === 'string' ? category.sUrlPath : category.sUrlPath[0],
      this.translocoService.getActiveLang(),
    );
  }

  public getCatalogPathByLocation(path: string | string[]): string {
    if (Array.isArray(path)) {
      const urlLocation = this.location.path().split('?')[0].slice(4);
      return path.find((item) => item.includes(urlLocation)) || path[0];
    }

    return path;
  }
}
